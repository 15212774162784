import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n

	.use(initReactI18next)

	.init({
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: {
					Login: 'Log In',
					pleaseProvide: 'Please provide your mobile number:',
					notRegister: 'Haven’t Signed Up Yet?',
					registerNow: 'Sign Up Now',
					Registration: 'Registration',
					fillForm: 'Please fill the form to register',
					fullName: 'Full Name',
					phoneNumber: 'Phone Number',
					State: 'State',
					register: 'Register',
					registerSuccess: 'Registered',
					registerSuccessDesc: 'Your account has been successfully registered.',
					goToHomepage: 'Go to homepage',
					availableReward: 'Available Rewards',
					viewAll: 'View All',
					howitWorks: 'How It Works',
					scanNWin: 'Scan & Win',
					scanNRedeem: 'Scan & Redeem',
					Buy: 'Buy',
					buyDesc: 'Buy participating Mi Sedaap products.',
					Scan: 'Scan',
					scanDesc: 'Scan unique code printed on the inner of packaging.',
					SnapButtonDesc: 'Snap Unique Code',
					spin: 'Spin',
					spinDesc: '1 successful scan entitled to 1 Sedaap Spin for 1 reward redemption.',
					Rewards: 'Rewards',
					spintitle: 'SEDAAP SPIN',
					rewardDesc: 'Purchase more for more spins and rewards!',
					rewardShop: 'Rewards Shop',
					myRewards: 'My Rewards',
					redeemReward: 'Redeem Reward?',
					areYouSure: 'Are you sure you want to redeem this reward? Your ',
					willBeDeduct: 'points will be deducted once confirmed.',
					cancel: 'Cancel',
					confirm: 'Confirm',
					success: 'Success!',
					successRedeem: 'You have successfully completed your redemption.',
					proceedToReward: 'Proceed to My Rewards',
					itemDetails: 'Item Details',
					deliveryAddress: ' Delivery Address',
					trackOrder: 'Track Your Order',
					deliveryStatus: 'Delivery Status',
					Remark: 'Remark',
					CallCareline: 'Call DESSINI careline for Delivery Tracking',
					day: 'Monday - Friday (Except Public Holiday)',
					rewarded: 'Rewarded Date',
					HowToRedeem: 'How to redeem?',
					tnc: 'Terms & Conditions',
					home: 'Home',
					scannedHistory: 'Scanned History',
					scannedOn: 'Scanned on',
					myProfile: 'My Profile',
					personalDetails: 'Personal Details',
					deliveryDetails: 'Delivery Details',
					FAQ: 'FAQ',
					Logout: 'Log Out',
					emailAddress: 'Email Address',
					monthOfBirth: 'Month of Birth',
					optional: 'Optional',
					personalSuccess: 'Your details have been successfully updated.',
					editDeliveryAddress: 'Edit Delivery Address',
					addNewAddress: 'Add New Address',
					save: 'Save',
					deliveryDesc: 'Enter your address for delivery.',
					line1: 'Address line 1',
					line1placeHolder: 'Enter your address (line 1)',
					line2: 'Address line 2',
					line2placeHolder: 'Enter your address (line 2)',
					city: 'City',
					postcode: 'ZIP / Postcode',
					done: 'Done',
					addressSaved: 'Address has been successfully saved.',
					scanQR: 'Scan QR',
					scanText: 'Scan Text',
					scanCode: 'Scan or Key In Unique Code',
					scanQRDesc: 'Scan the QR code inside of your Mi Sedaap bundle packaging',
					scanCodeDesc: 'You can find the unique code inside of your Mi Sedaap bundle packaging.',
					or: 'Or',
					submit: 'Submit',
					scanplaceHolder: 'Key in unique code',
					spinDesc2: 'Spin the wheel now to get your reward',
					spinBtn: 'Spin The Wheel',
					congrats: 'Congratulations!',
					youEarned: 'You have earned',
					addtoReward: 'Add to My Rewards',
					addtoRewardDesc: 'Add to your account before it disappears',
					tnc1: 'TERMS & CONDITIONS',
					tnc2: 'Effective Date: 1 March 2024 ',
					tnc3: 'Updated On: 1 March 2024 ',
					tnc4: 'The rewards, privileges and membership terms and conditions of Sedaap Rewards (“Program”) launched on 1st March 2024 is created, owned and managed by Gentle Supreme Sdn Bhd.',
					tnc5: 'By joining this Program, you agree to be bound by the Terms and Conditions of this Program (“Program Terms”) and our Privacy Policy. ',
					tnc6: '1. Eligibility',
					tnc7: 'This Program is open to all individual residents of Peninsular Malaysia with valid/registered mobile number and who are aged 18 years and above. ',
					tnc8: '2. Joining the Program',
					tnc9: 'Eligible individuals may join the Program to become a Sedaap Rewards Member through any of options: - ',
					tnc10:
						'Scan Mi Sedaap product packaging QR code and sign up for a Sedaap Rewards account with ONE (1) valid Malaysia registered mobile number at Sedaap Rewards.  ',
					tnc11:
						'Scan QR code on Mi Sedaap POSM or associated social media channels and or shared links to sign up for a Sedaap Rewards account with ONE (1) valid Malaysia registered mobile number at Sedaap Reward.  ',
					tnc12:
						'Scan Mi Sedaap / Sedaap Rewards’ promoter QR code to sign up for a Sedaap Rewards account with ONE (1) valid Malaysia registered mobile number at Sedaap Rewards.',
					tnc13: '3. Types of Rewards and Validity ',
					tnc14:
						'3.1 Members of the Program are entitled to (1) Sedaap Spin by successful scan or key in of product unique code printed on the inner packaging of Mi Sedaap participating product(s) during the stated campaign period. The range of participating Mi Sedaap’s products as below:',
					tnc15: 'Participating Products (Excluding Mi Sedaap Cup and Multicup) ',
					tnc16: 'Mi Sedaap products may change from time to time at the sole discretion of Gentle Supreme Sdn Bhd.',
					tnc17:
						'3.2 Rewards are earned and can be accumulated, strictly through successful scan or key in of product unique code printed on the inner packaging of Mi Sedaap participating product(s) during the stated campaign period. Purchases of non-participating Mi Sedaap products will not qualify for Sedaap Spin. ',
					tnc18:
						'3.3 Gentle Supreme Sdn Bhd may from time to time, in its sole discretion, change the number of spins tagged to the QR codes or rewards of the participating Mi Sedaap product(s) without prior notice to you. ',
					tnc19:
						'3.4 Rewards redeemed are non-transferable and non-exchangeable for (i) cash (in full or in part), (ii) designated products or (iii) non-designated Rewards (as defined hereafter) and may only be used by the Members for purposes outlined in the Program.  ',
					tnc20: 'Types of Rewards: ',
					tnc205: 'Redemption',
					tnc21:
						'4.1 Any redemption submitted by and or awarded to Sedaap Rewards member cannot be exchanged, cancelled or returned and are subject to availability. ',
					tnc22:
						'4.2 Digital gift card / e voucher / voucher code is applicable for one (1) time use/redemption and for one (1) transaction only. Their usage is subject to the issuer’s /provider’s terms and conditions. They cannot be combined with other promotions or discounts. ',
					tnc23:
						'4.3 Redemption items delivered do not include accessories and batteries. In addition, items which has a choice of colour will be delivered on random basis, unless specified in the catalogue. ',
					tnc24:
						'4.4 Gentle Supreme Sdn Bhd reserves the right to discontinue any participating Mi Sedaap products and / or any redemption item and substitute it with a similar item of equal value for redemption at any time without prior notice. ',
					tnc25:
						'4.5 Sedaap Rewards member is advised to examine the redemption items upon receipt. Should there be any good(s) received are faulty, damaged, and / or defective, please contact Whatsapp helpline in Sedaap Rewards (Mon to Fri, 9am – 6pm, except Public Holidays) within 24 hours from date of receipt to lodge a report AND to return the good(s) immediately in the original packaging within 1 week to the redemption centre at:  ',
					tnc26:
						'4.6 Gentle Supreme Sdn Bhd reserves the right to reject any redemption or request for receipt verification to validate the authenticity of the purchase if fraudulent activity is suspected. ',
					tnc27: '5. Termination and Changes to the Program ',
					tnc28:
						'5.1 Gentle Supreme Sdn Bhd reserves the right in its sole discretion, to revoke your membership in the Program and/or to audit your membership account at any time if fraudulent activity is suspected. If your membership is revoked, any rewards or benefits in your Program account will automatically expire and your access to the Program will automatically terminate. If Gentle Supreme Sdn Bhd suspects illegal activity, fraud, misrepresentation, abuse or violation of these Terms, Gentle Supreme Sdn Bhd also reserves the right to take appropriate legal action, in its sole discretion. ',
					tnc29:
						'5.2 Gentle Supreme Sdn Bhd may, in its discretion, modify, restrict, supplement or terminate these Program Terms, and/or the Program at any time without prior notice. [If Gentle Supreme Sdn Bhd amends these Program Terms, an updated version of these Program Terms will reflect those changes and we will notify you of such changes by updating the effective date at the top of these Program Terms.] ',
					tnc30: '6. Personal Data ',
					tnc31:
						'6.1 The personal information collected from you in connection with the Program will be processed by Gentle Supreme Sdn Bhd in accordance with our Privacy Policy',
					tnc32:
						'6.2 By registering as a member of this Program, you are deemed to have read and understood these Terms and Conditions and have given your consent to Gentle Supreme Sdn Bhd to process your personal data and disclose the same to third parties providing services to Gentle Supreme Sdn Bhd to provide you with the services you request and to respond to your questions in relation to this Program. You also give your consent to Gentle Supreme Sdn Bhd to communicate with you in one or more of the following manners i.e., mail, email, phone, SMS and WhatsApp, to provide you with the services you request and to respond to your questions in relation to this Program. ',
					faq1: 'What is Sedaap Rewards?',
					faq2: 'Sedaap Rewards (“Program”) is a program organized by the Gentle Supreme Sdn Bhd (“Organizer”). Registered shoppers (“Participants”) will be entitled to ONE (1) Sedaap Spin opportunity within Sedaap Rewards after a successful scan or key in of product unique code printed on the inner packaging of participating Mi Sedaap products.',
					faq3: 'What is the participating Mi Sedaap products? ',
					faq4: 'How long is the duration of this campaign?',
					faq5: 'This campaign will run from 00:00 on 01st MARCH 2024 to 23:59 on 31st DECEMBER 2024 (Malaysian Time Zone) (“Program Period”) or upon full redemption of Sedaap Rewards awarded under this Program, whichever is the earlier. ',
					faq6: 'How many times can I spin?',
					faq7: 'Each participant is entitled for ONE (1) Sedaap Spin in ONE (1) successful scan or key in of product unique code printed on the inner packaging of Mi Sedaap participating products during the stated campaign period.',
					faq8: 'Where can I check my claimed reward(s)?',
					faq9: 'Every successful spinned and claimed reward(s) will be recorded in ‘My Rewards’. Participant are able to navigate via homepage hamburger icon. ☰',
					faq10: 'Why is my Sedaap Spin reward not recorded in ‘My Rewards’?',
					faq11:
						'Each registered participant is required to successfully claim the reward when the pop up is being prompted after ONE (1) successful Sedaap Spin for it to be a recorded claim of reward under ‘My Rewards’. Unregistered participant will be prompted to complete registration for a successful claim of reward.',
					faq12: 'What if I did not claim the rewards in time?',
					faq13: 'If you did not manage to secure your spin reward when the prompted pop up appeared, your reward will not be recorded.',
					faq14: 'Where can I send my enquiries or feedback?',
					faq15:
						'You may contact us through Sedaap Rewards WhatsApp Hotline at +6019 950 7057 (Monday to Friday, 9am to 6pm, except Public Holiday).',
					faq16: 'What are the rewards?',
					faq17:
						'Participants are entitled to redeem Touch n Go RM1, RM2, RM3, RM5 & RM10 eWallet Reload PIN and Points of 10 & 20 for redemption of Premiums (Kitchenware). ',
					faq18: 'When will the redeemed Premiums be delivered? ',
					faq19:
						'Organizer will contact the participant via Whatsapp on the confirmation of delivery details based on their account to proceed with fulfillment of prizes. Upon successful redemption, packing and delivery will take approximately 4 – 6 weeks.',
					howtotng1: "Open the Touch 'n Go eWallet application.",
					howtotng2: "Tap 'Add Value.'",
					howtotng3: "Tap 'eWallet Balance.'",
					howtotng4: "Tap 'TNG eWallet Top-Up PIN' below.",
					howtotng5: "Enter the TNG eWallet Top-Up PIN and tap 'Add Value.'",
					howtotng6: "Successfully redeem Touch 'n Go eWallet vouchers.",
					howtoshopee1: 'Valid from redemption time until 31 Dec 2024 23:59pm',
					howtoshopee2: 'Applicable for new & existing Shopee users',
					howtoshopee3: 'Valid for one-time use only, with a minimum spend of RM5',
					howtoshopee4: 'This voucher is only applicable to Mi Sedaap products sold by Gentle Supreme Official Store in Shopee',
					howtoshopee5: 'Voucher is not refundable, non-transferable, not redeemable for cash & cannot be resold.',
					howtolazada1: 'Valid from redemption time until 31 Dec 2024 23:59pm',
					howtolazada2: 'Applicable for new & existing Lazada users',
					howtolazada3: 'Valid for one-time use only, with a minimum spend of RM10',
					howtolazada4: 'This voucher is only applicable to Mi Sedaap products sold by Gentle Supreme Official Store in Lazada',
					howtolazada5: 'Voucher is not refundable, non-transferable, not redeemable for cash & cannot be resold.',
					policy1: '1. Introduction ',
					policy2:
						'We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you join our Sedaap Rewards program and tell you about your privacy rights and how the law protects you. ',
					policy3: '2. How We Collect Personal Data ',
					policy4:
						'We collect information about you directly from you, automatically through your use of our Site, and from third parties as following: ',
					policy5:
						'Sedaap Rewards websites. Consumer-directed websites operated by or for Gentle Supreme Sdn Bhd, including sites that We operate under our own domains/URLs and mini-sites that We run on third party social networks such as Facebook or Instagram (“Websites”). ',
					policy6: 'E-mail, text and other electronic messages. Interactions with electronic communications between you and Gentle Supreme Sdn Bhd. ',
					policy7: 'Gentle Supreme Sdn Bhd CES. Communications with our Consumer Engagement Centre (“CES”). ',
					policy8:
						'Offline registration forms. Printed or digital registration and similar forms that We collect via, for example, postal mail, in-store demos, points of sale, campaigns, contests and events. ',
					policy9:
						'Advertising interactions. Interactions with our advertisements (e.g., if you interact with one of our ads on a third-party website, we may receive information about that interaction). ',
					policy10:
						'Data We create. During our interactions with you, we may create Personal Data about you (e.g. records of your purchases from our websites). ',
					policy11:
						'Data from other sources. Third party social networks (e.g. such as Facebook, Google), market research (if feedback not provided on an anonymous basis), third party data aggregators, Gentle Supreme Sdn Bhd promotional partners, public sources and data received when We acquire other companies. ',
					policy12: 'Gentle Supreme Sdn Bhd On Ground Events. Any on-ground events, campaigns or promotions organized by Us ',
					policy13: '3. Personal Data We Collect & Use ',
					policy14: 'We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows: ',
					policy15:
						'Identity Data includes any information you provide to Us that would allow Us to identify you, such as first name, last name, username or similar identifier. ',
					policy16:
						'Contact Data includes any information you provide to Us that would allow Us to contact you, such as billing address, delivery address, email address and telephone numbers. ',
					policy17:
						'Transaction Data includes transactional details or other details of products and services you have purchased from us. Examples include purchased brand, location, date and time. ',
					policy18:
						'Technical Data includes information about the computer system or other technological device that you use to access our Websites, such as the Internet protocol (IP) address used to connect your computer or device to the Internet, operating system type, and web browser type and version. If you access the website via a mobile device such as a smartphone, the collected information will also include, where permitted, your phone’s unique device ID, advertising ID, geo-location, and other similar mobile device data. ',
					policy19:
						'Websites/Communication Usage Data includes information about your actions navigate through and interact with our Websites or newsletters. This includes information such as which links you click on, which pages or content you view and for how long, and other similar information and statistics about your interactions, such as content response times, download errors and length of visits to certain pages. This information is captured using automated technologies such as cookies and web beacons, and is also collected through the use of third party tracking for analytics and advertising purposes.  ',
					policy20:
						'Account login Data includes information that is required to give you access to your specific account profile. Examples include your login ID/email address, screen name, password in unrecoverable form, and/or security question and answer. ',
					policy21:
						'Profile Data includes your username and password, date of birth, age, gender, number of people in your family, preferences, feedback and survey responses, favourite products, hobbies and interests, and household or lifestyle information. ',
					policy22:
						'Consumer-generated Content includes content that you create and then uploading it to our Website. Examples include photos, videos, personal stories, or other similar media or content. Where permitted, We collect and publish consumer-generated content in connection with a variety of activities, including contests and other promotions, website community features, consumer engagement, and third party social networking. ',
					policy23: '4. How We Use Personal Data ',
					policy24:
						'We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: ',
					policy25:
						'Consumer service. We use your Personal Data for consumer service purposes, including responding to your enquiries. This typically requires the use of certain personal contact information and information regarding the reason for your inquiry (e.g. order status, technical issue, product question/complaint, general question, etc.). ',
					policy26:
						'Contests, marketing and other promotions. With your consent (where required), We use your Personal Data to provide you with information about goods or services (e.g. marketing communications, campaigns, promotions or contests). This can be done via any means such as email, ads, SMS, phone calls, instant messaging applications such as WhatsApp etc and postal mailings to the extent permitted by applicable laws. We may also disclose your Personal Data including name, NRIC no. or personal contact information for the purposes of identifying you and Photographs or Videos in public when you participate in a campaign, event or contest in On Ground Events. ',
					policy27:
						'Third party social networks. We may provide functionality on the Site that will allow you to forward and share certain content with a friend or colleague. If you choose to use Gentle Supreme Sdn Bhd sharing functionality to refer someone to our content, products or services, we will use your data and that of the individual you are sharing with to send the content or link you request. In addition, we may offer access to third party sharing functionality, such as third party social media widgets/tools/buttons. If you use that functionality, your use is subject to the third party’s privacy policy and terms. As with all links to non- Gentle Supreme Sdn Bhd websites/content/services, we recommend that you read the privacy policies and terms associated with third party properties carefully. ',
					policy28:
						'Personalisation (offline and online). With your consent (where required), We use your Personal Data (i) to analyse your preferences and habits, (ii) to anticipate your needs based on our analysis of your profile, (iii) to improve and personalise your experience on our Websites and apps; (iv) to ensure that content from our Websites/apps is optimised for you and for your computer or device; (v) to provide you with targeted advertising and content, and (vi) to allow you to participate in interactive features, when you choose to do so. For example, We remember your login ID/email address or screen name so that you can quickly login the next time you visit our site or so that you can easily retrieve the items you previously placed in your shopping cart. Based on this type of information, and with your consent (where required), We also show you specific Gentle Supreme Sdn Bhd content or promotions that are tailored to your interests. The use of your Personal Data is voluntary, which means that you can oppose the processing of your Personal Data for this purpose. For detailed information on how to opt-out please refer to Section 10 below. ',
					policy29:
						'Order fulfilment. We use your Personal Data to process and ship your orders, inform you about the status of your orders, correct addresses and conduct identity verification and other fraud detection activities. This involves the use of certain Personal Data and payment information. This can be done via any means such as email, ads, SMS, phone calls, instant messaging applications such as WhatsApp etc and postal mailings to the extent permitted by applicable laws. ',
					policy30:
						'Other general purposes (e.g. internal or market research, analytic, security). In accordance with applicable laws, We use your Personal Data for other general business purposes, such as maintaining your account, conducting internal or market research and measuring the effectiveness of advertising campaigns. We also use your Personal Data for management and operation of our communications, IT and security systems. ',
					policy31:
						'Legal reasons or merger/acquisition. If Gentle Supreme Sdn Bhd or its assets are acquired by, or merged with, another company including through bankruptcy, We will share your Personal Data with any of our legal successors. We will also disclose your Personal Data to third parties (i) when required by applicable law; (ii) in response to legal proceedings; (iii) in response to a request from a competent law enforcement agency; (iv) to protect our rights, privacy, safety or property, or the public; or (v) to enforce the terms of any agreement or the terms of our Website. ',
					policy32: '5. How We Disclose Your Personal Data ',
					policy33: 'Gentle Supreme Sdn Bhd may disclose your personal data for our business purposes as follows: ',
					policy34:
						'Service providers. These are external companies that We use to help Us run our business (e.g. order fulfilment, payment processing, fraud detection and identity verification, website operation, market research companies, support services, promotions, website development, data analysis, CRC, etc.). Service providers, and their selected staff, are only allowed to access and use your Personal Data on Our behalf for the specific tasks that they have been requested to carry out, based on our instructions, and are required to keep your Personal Data confidential and secure. ',
					policy35:
						'To Comply with Law/Protect. We reserve the right to disclose your personal data as required by law, when we believe disclosure is necessary or appropriate to comply with a regulatory requirement, judicial proceeding, court order, government request, or legal process served on us, or to protect the safety, rights, or property of our customers, the public, Gentle Supreme Sdn Bhd or others. ',
					policy36:
						'Third party recipients using Personal Data for legal reasons or due to merger/acquisition. We will disclose your Personal Data to third parties for legal reasons or in the context of an acquisition or a merger. ',
					policy37: '6. Retention Period ',
					policy38:
						'In accordance with applicable laws, We will use your Personal Data for as long as necessary to satisfy the purposes for which your Personal Data was collected or to comply with applicable legal requirements. Personal data used to provide you with a personalized experience will be kept for a duration permitted by applicable laws. ',
					policy39: '7. Disclosure, Storage and/or Transfer of Your Personal Data ',
					policy40:
						'People who can access your Personal Data. Your Personal Data will be processed by our authorized staff or agents, on a need-to-know basis, depending on the specific purposes for which your Personal Data has been collected. ',
					policy41:
						'Measures taken in operating environments. We store your Personal Data in operating environments that use reasonable security measures to prevent unauthorized access. We follow reasonable standards to protect Personal Data. The transmission of information via the Internet is, unfortunately, not completely secure and although We will do our best to protect your Personal Data, We cannot guarantee the security of the data during transmission through our websites/apps. ',
					policy42:
						'Measures We expect you to take. It is important that you also play a role in keeping your Personal Data safe and secure. When signing up for an online account, please be sure to choose an account password that would be difficult for others to guess and never reveal your password to anyone else. You are responsible for keeping this password confidential and for any use of your account. If you use a shared or public computer, never choose to have your login ID/email address or password remembered and make sure to log out of your account every time you leave the computer. You should also make use of any privacy settings or controls We provide you in our website/app. ',
					policy43:
						'Transfer of your Personal Data. Because of the international nature of our business, We may need to transfer your personal data within the Gentle Supreme Sdn Bhd group, and to third parties, in connection with the purposes set out in this Privacy Notice. For this reason, We may transfer your personal data to other countries that may have different laws and data protection compliance requirements to those that apply in Malaysia. ',
					policy44: '8. Changes to This Notice ',
					policy45:
						'This policy may be updated from time to time. We encourage you to review it regularly to stay informed about how we are protecting your information. ',
					policy46: '9. Contact Us ',
					policy47:
						'You have rights under certain privacy laws in relation to your personal data including the right to receive a copy of the personal data we hold about you. To ask questions or make comments on this Notice and our privacy practices or to make a complaint about our compliance with applicable privacy laws, please contact us at: +6019 950 7057 [Hotline for Sedaap Rewards] ',
					welcometo: 'Welcome to',
					landingtext: 'Scan the unique code printed on the inner packaging of Mi Sedaap bundle pack packaging and get rewarded!',
					landingtext2: ' bundle pack packaging and get rewarded!',
					landingtextAllFlavor: 'ALL FLAVOURS',
					getstarted: 'Get Started',
					policyprivacy: 'Privacy Policy',
					SelectState: 'Select State',
					haventSignup: "Haven't Signed Up Yet?",
					signupNow: 'Sign Up Now',
					name: 'Name',
					mobileNumber: 'Mobile Number',
					successAddress: 'Address has been successfully updated.',
					howtoRedeemTNG: "How to redeem Touch 'n Go eWallet credit",
					termandcondition1: 'Only ONE (1) Reload PIN Reward can be used per reload.',
					termandcondition2:
						'To utilize the Reload Pin, log in on the microsite. At ‘Rewards’, tap on the ‘View Now’ button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.',
					termandcondition3:
						'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.',
					termandcondition4: 'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.',
					termandcondition5: 'Reload Pin will be expired on 31 December 2024. ',
					someTnc1: 'Touch ‘n Go RM 1 eWallet Reload Pin ',
					someTnc2: 'Touch ‘n Go RM 2 eWallet Reload Pin ',
					someTnc3: 'Touch ‘n Go RM 3 eWallet Reload Pin ',
					someTnc4: 'Touch ‘n Go RM 5 eWallet Reload Pin',
					someTnc5: 'Touch ‘n Go RM 10 eWallet Reload Pin',
					someTnc6: 'Sedaap Points for Premiums Redemption',
					rewardTngDesc: "Touch 'n go eWallet Reload Pin valued at ",
					orderId: 'Order ID:',
					all: 'All',
					peninsularOnly: 'This program is only available in Peninsular Malaysia',
					selectAddress: 'Select Address',
					errorName: 'Please input your name!',
					errorNumber: 'Please input your number!',
					errorValidNumber: 'Please enter a valid number!',
					errorAddress: 'Please input your address!',
					errorState: 'Please input your state!',
					errorCity: 'Please input your city!',
					errorPostcode: 'Please input your postcode!',
					errorPostcodeDigit: 'Please enter a 5-digit postcode!',
					pleaseAvoidSpecialCharactersInName: 'Please avoid using special characters in Name input field',
					items: 'Item',
					referTNC: '*Refer to T&C for full list of participating products',
					close: 'Close',
					invalidPhoneNumber: 'You have entered an invalid phone number',	
					invalidEmail: 'You have entered an invalid email',
					invalidNRIC: 'You have entered an invalid NRIC number',
				},
			},
			bm: {
				translation: {
					close: 'Tutup',
					Login: 'Log Masuk',
					pleaseProvide: 'Sila isikan nombor telefon bimbit anda:',
					notRegister: 'Belum mendaftar?',
					registerNow: 'Daftar Sekarang',
					Registration: 'Pendaftaran',
					fillForm: 'Sila isi borang untuk mendaftar',
					fullName: 'Name Penuh',
					phoneNumber: 'Nombor Telefon',
					State: 'Negeri',
					register: 'Daftar',
					registerSuccess: 'Berdaftar',
					registerSuccessDesc: 'Akaun anda telah berjaya didaftarkan.',
					goToHomepage: 'Teruskan ke laman utama',
					availableReward: 'Ganjaran Tersedia',
					viewAll: 'Lihat semua',
					howitWorks: 'Cara Berfungsi',
					scanNWin: 'Imbas & Menang',
					scanNRedeem: 'Imbas & Tebus',
					Buy: 'Beli',
					buyDesc: 'Beli produk Mi Sedaap yang menyertai*',
					Scan: 'Imbas',
					scanDesc: 'Imbas kod unik yang dicetak di dalam pembungkusan.',
					SnapButtonDesc: 'Snap Kod Unik',
					spin: 'Putar',
					spinDesc: '1 imbasan yang berjaya layak untuk 1 Putaran Roda Sedaap untuk menebus ganjaran',
					Rewards: 'Ganjaran',
					spintitle: 'RODA SEDAAP',
					rewardDesc: 'Beli lebih untuk lebih banyak putaran dan ganjaran!',
					rewardShop: 'Kedai Ganjaran',
					myRewards: 'Ganjaran Saya',
					redeemReward: 'Tebus Ganjaran?',
					areYouSure: 'Adakah anda pasti ingin menebus ganjaran ini? Mata ganjaran ',
					willBeDeduct: 'anda akan ditolak setelah disahkan.',
					cancel: 'Batal',
					confirm: 'Sahkan',
					success: 'Berjaya!',
					successRedeem: 'Anda telah berjaya menyelesaikan penebusan anda.',
					proceedToReward: 'Lanjut ke Ganjaran Saya',
					itemDetails: 'Butiran Item',
					deliveryAddress: 'Alamat Penghantaran',
					trackOrder: 'Lacak Pesanan Anda',
					deliveryStatus: 'Status Penghantaran',
					Remark: 'Catatan',
					CallCareline: 'Panggilan talian careline DESSINI untuk Status Penghantaran',
					day: 'Isnin - Jumaat ( kecuali hari cuti umum )',
					rewarded: 'Tarikh Ganjaran',
					HowToRedeem: 'Bagaimana untuk menebus?',
					tnc: 'Terma & Syarat',
					home: 'Laman Utama',
					scannedHistory: 'Imbasan',
					scannedOn: 'Imbas pada',
					myProfile: 'Profil Saya',
					personalDetails: 'Butiran Peribadi',
					deliveryDetails: 'Butiran Penghantaran',
					FAQ: 'Soalan Lazim',
					Logout: 'Log Keluar',
					emailAddress: 'Alamat Emel',
					monthOfBirth: 'Bulan Kelahiran',
					optional: 'Pilihan',
					personalSuccess: 'Butiran anda telah berjaya dikemaskini.',
					editDeliveryAddress: 'Edit Alamat Penghantaran',
					addNewAddress: 'Tambah Alamat Baru',
					save: 'Simpan',
					deliveryDesc: 'Masukkan alamat anda untuk penghantaran.',
					line1: 'Baris Alamat 1',
					line1placeHolder: 'Masukkan alamat anda (baris 1)',
					line2: 'Baris Alamat 2',
					line2placeHolder: 'Masukkan alamt anda (baris 2)',
					city: 'Bandar',
					postcode: 'Poskod',
					done: 'Selesai',
					addressSaved: 'Alamat telah berjaya disimpan',
					scanQR: 'Imbas QR',
					scanText: 'Imbas Teks',
					scanCode: 'Imbas atau Masukkan Kod Unik',
					scanQRDesc: 'Imbad kod QR di dalam pembungkusan bundel Mi Sedaap anda.',
					scanCodeDesc: 'Anda boleh menemui kod unik di dalam bungkusan Mi Sedaap anda.',
					or: 'Atau',
					submit: 'Hantar',
					scanplaceHolder: 'Masukkan kod unik',
					spinDesc2: 'Putarkan Sedaap Spin sekarang untuk mendapatkan ganjaran anda',
					spinBtn: 'Putarkan Sedaap Spin',
					congrats: 'Tahniah!',
					youEarned: 'Anda telah memperoleh',
					addtoReward: 'Tambahkan ke Ganjaran Saya',
					addtoRewardDesc: 'Tambahkan ke akaun anda sebelum mata ganjaran anda hilang',
					tnc1: 'TERMA & SYARAT ',
					tnc2: 'Tarikh Kuatkuasa: 1 Mac 2024  ',
					tnc3: 'Tarikh Kemaskini Terakhir: 1 Mac 2024 ',
					tnc4: 'Syarat-syarat ganjaran, keistimewaan, dan keahlian bagi Sedaap Rewards yang dilancarkan pada 1 Mac 2024 dicipta, dimiliki, dan dikendalikan oleh Gentle Supreme Sdn Bhd. ',
					tnc5: 'Dengan menyertai Program ini, anda bersetuju terikat dengan Terma dan Syarat Program ini dan Dasar Privasi kami. ',
					tnc6: '1. Kelayakan',
					tnc7: 'Program ini terbuka kepada semua penduduk individu Semenanjung Malaysia yang mempunyai nombor telefon bimbit yang sah/berdaftar dan berumur 18 tahun ke atas.  ',
					tnc8: '2. Menyertai Program ',
					tnc9: 'Individu yang layak boleh menyertai Program untuk menjadi ahli Sedaap Rewards melalui mana-mana pilihan berikut:  ',
					tnc10:
						'Imbas kod QR bungkusan produk Mi Sedaap dan daftar akaun Sedaap Rewards dengan SATU (1) nombor telefon berdaftar Malaysia di Sedaap Rewards. ',
					tnc11:
						'Imbas kod QR pada POSM Mi Sedaap atau saluran media sosial yang berkaitan dan/atau pautan yang dikongsi untuk mendaftar akaun Sedaap Rewards dengan SATU (1) nombor telefon berdaftar Malaysia di Sedaap Reward.  ',
					tnc12:
						'Imbas kod QR promotor Mi Sedaap / Sedaap Rewards untuk mendaftar akaun Sedaap Rewards dengan SATU (1) nombor telefon berdaftar Malaysia di Sedaap Rewards. ',
					tnc13: '3. Jenis-jenis Ganjaran dan Kekalkan ',
					tnc14:
						'3.1 Ahli Program berhak kepada (1) Putaran Sedaap dengan pindaan atau kemasukan yang berjaya bagi kod unik produk pada bungkusan dalaman produk Mi Sedaap yang menyertai dalam tempoh kempen yang dinyatakan. Produk Mi Sedaap yang menyertai termasuk:',
					tnc15: 'Produk Partisipan (Tidak Termasuk Mi Sedaap Cup dan Multicup) ',
					tnc16: 'Produk Mi Sedaap mungkin berubah dari semasa ke semasa atas budi bicara tunggal Gentle Supreme Sdn Bhd. ',
					tnc17:
						'3.2 Ganjaran diperolehi dan boleh dikumpulkan, secara ketat melalui pindaan atau kemasukan yang berjaya bagi kod unik produk pada bungkusan dalaman produk Mi Sedaap yang menyertai dalam tempoh kempen yang dinyatakan. Pembelian produk Mi Sedaap yang bukan penyertaan tidak layak untuk Putaran Sedaap. ',
					tnc18:
						'3.3 Gentle Supreme Sdn Bhd boleh dari semasa ke semasa, atas budi bicara tunggalnya, mengubah bilangan putaran yang berkaitan dengan kod QR atau ganjaran produk Mi Sedaap yang menyertai tanpa notis terlebih dahulu kepada anda. ',
					tnc19:
						'3.4 Ganjaran yang ditebus tidak boleh dipindahkan dan tidak boleh ditukar dengan (i) wang (sepenuhnya atau sebahagiannya), (ii) produk tertentu, atau (iii) Ganjaran bukan tertentu (seperti yang ditakrifkan selepas ini), dan hanya boleh digunakan oleh Ahli untuk tujuan yang dinyatakan dalam Program. ',
					tnc20: 'Jenis-jenis Ganjaran: ',
					tnc205: 'Penebusan ',
					tnc21:
						'4.1 Sebarang penebusan yang dihantar oleh ahli Sedaap Rewards atau dianugerahkan kepada mereka tidak boleh ditukar, dibatalkan, atau dikembalikan dan tertakluk kepada ketersediaan. ',
					tnc22:
						'4.2 Kad hadiah digital / e-voucher / kod baucar digunakan sekali sahaja dan untuk satu urus niaga sahaja. Penggunaannya tertakluk kepada terma dan syarat penerbit / penyedia. Mereka tidak boleh digabungkan dengan promosi atau diskaun lain. ',
					tnc23:
						'4.3 Barang-barang yang ditebus tidak termasuk aksesori dan bateri. Selain itu, barang-barang yang mempunyai pilihan warna akan dihantar secara rawak, kecuali dinyatakan dalam katalog.',
					tnc24:
						'4.4 Gentle Supreme Sdn Bhd berhak untuk menghentikan mana-mana produk Mi Sedaap yang menyertai dan / atau sebarang item penebusan dan menggantikannya dengan item yang serupa dengan nilai yang sama untuk penebusan pada bila-bila masa tanpa notis terlebih dahulu.',
					tnc25:
						'4.5 Ahli Sedaap Rewards dinasihatkan untuk memeriksa item penebusan setelah diterima. Jika ada barang yang diterima rosak, kerosakan, dan / atau cacat, sila hubungi talian bantuan Whatsapp di Sedaap Rewards (Isnin hingga Jumaat, 9 pagi - 6 , kecuali Hari Cuti Umum) dalam tempoh 24 jam dari tarikh penerimaan untuk membuat laporan DAN mengembalikan barang tersebut dengan segera dalam pembungkus asal dalam masa 1 minggu ke pusat penebusan di: ',
					tnc26:
						'4.6 Gentle Supreme Sdn Bhd berhak untuk menolak sebarang penebusan atau permintaan untuk pengesahan resit bagi mengesahkan keautentikan pembelian jika kegiatan penipuan disyaki.',
					tnc27: '5. Pemutusan dan Perubahan kepada Program ',
					tnc28:
						'5.1 Gentle Supreme Sdn Bhd berhak atas budi bicaranya sendiri, untuk mencabut keahlian anda dalam Program dan/atau memeriksa akaun keahlian anda pada bila-bila masa jika kegiatan penipuan disyaki. Jika keahlian anda dicabut, sebarang ganjaran atau faedah dalam akaun Program anda akan luput secara automatik dan akses anda ke Program akan berakhir secara automatik. Jika Gentle Supreme Sdn Bhd mencurigai aktiviti haram, penipuan, pemalsuan, penyalahgunaan atau pelanggaran Terma ini, Gentle Supreme Sdn Bhd juga berhak untuk mengambil tindakan undang-undang yang wajar, atas budi bicaranya sendiri.  ',
					tnc29:
						'5.2 Gentle Supreme Sdn Bhd boleh, atas budi bicaranya, meminda, menyekat, melengkap atau menghentikan Terma Program ini, dan/atau Program pada bila-bila masa tanpa notis terlebih dahulu. [Jika Gentle Supreme Sdn Bhd mengubah Terma Program ini, versi terkini Terma Program ini akan mencerminkan perubahan tersebut dan kami akan memberitahu anda mengenai perubahan tersebut dengan mengemaskini tarikh kuat kuasa di bahagian atas Terma Program ini.]',
					tnc30: '6. Data Peribadi',
					tnc31:
						'6.1 Maklumat peribadi yang dikumpulkan daripada anda berkaitan dengan Program ini akan diproses oleh Gentle Supreme Sdn Bhd mengikut Dasar Polisi kami. ',
					tnc32:
						'6.2 Dengan mendaftar sebagai ahli Program ini, anda dianggap telah membaca dan memahami Terma dan Syarat ini dan telah memberikan persetujuan anda kepada Gentle Supreme Sdn Bhd untuk memproses data peribadi anda dan mendedahkan perkara yang sama kepada pihak ketiga yang menyediakan perkhidmatan kepada Gentle Supreme Sdn Bhd untuk memberikan anda perkhidmatan yang anda minta dan menjawab soalan anda berhubung dengan Program ini. Anda juga memberikan persetujuan anda kepada Gentle Supreme Sdn Bhd untuk berkomunikasi dengan anda melalui satu atau lebih cara berikut iaitu surat, e-mel, telefon, SMS dan WhatsApp, untuk memberikan anda perkhidmatan yang anda minta dan menjawab soalan anda berkenaan Program ini. ',
					faq1: 'Apakah Sedaap Rewards?',
					faq2: 'Sedaap Rewards (Program) adalah program yang dianjurkan oleh Gentle Supreme Sdn Bhd (Penganjur). Pembeli yang berdaftar (Peserta) akan berhak mendapat SATU (1) peluang Sedaap Spin dalam Sedaap Rewards selepas pindaan atau kemasukan yang berjaya bagi kod unik produk pada bungkusan dalaman produk Mi Sedaap yang menyertai.',
					faq3: 'Apakah produk Mi Sedaap yang menyertai? ',
					faq4: 'Berapa lama tempoh kempen?',
					faq5: 'Kempen ini akan berlangsung dari 00:00 pada 01 MAC 2024 hingga 23:59 pada 31 DISEMBER 2024 (Zon Masa Malaysia) (Tempoh Program) atau sehingga penebusan penuh Sedaap Rewards di bawah Program ini, mana-mana yang lebih awal. ',
					faq6: 'Berapa kali saya boleh berputar? ',
					faq7: 'Setiap peserta berhak untuk SATU (1) Sedaap Spin dalam SATU (1) pindaan atau kemasukan yang berjaya bagi kod unik produk pada bungkusan dalaman produk Mi Sedaap yang menyertai dalam tempoh kempen yang dinyatakan.',
					faq8: 'Di mana saya boleh semak ganjaran yang saya klaim? ',
					faq9: 'Setiap Sedaap Spin yang berjaya dan ganjaran yang diklaim akan direkodkan dalam Ganjaran Saya. Peserta boleh navigasi melalui ikon hamburger laman utama. ☰',
					faq10: 'Mengapa ganjaran putaran saya tidak direkodkan dalam Ganjaran Saya? ',
					faq11:
						'Setiap peserta yang berdaftar dikehendaki untuk menuntut ganjaran apabila pop-up muncul selepas SATU (1) Sedaap Spin yang berjaya agar ianya direkodkan sebagai tuntutan ganjaran dalam Ganjaran Saya. Peserta yang belum berdaftar akan diminta untuk melengkapkan pendaftaran untuk tuntutan ganjaran yang berjaya.',
					faq12: 'Apa yang berlaku jika saya tidak sempat menuntut ganjaran dalam masa?',
					faq13: 'Jika anda tidak dapat menuntut ganjaran putaran apabila pop-up muncul, ganjaran anda tidak akan direkodkan.',
					faq14: 'Di mana saya boleh hantar pertanyaan atau maklum balas?',
					faq15:
						'Anda boleh menghubungi kami melalui talian bantuan WhatsApp Sedaap Rewards di +6019 950 7057 (Isnin hingga Jumaat, 9 pagi - 6 petang, kecuali Hari Cuti Umum).',
					faq16: 'Apakah ganjaran-ganjaran yang disediakan? ',
					faq17:
						"Peserta berhak untuk menebus PIN Tambah Nilai eWallet Touch 'n Go RM1, RM2, RM3, RM5 & RM10, dan Mata Sedaap 10 & 20 untuk penebusan Premium Barang-barang dapur.",
					faq18: 'Bilakah Premium yang ditebus akan dihantar?  ',
					faq19:
						'Penganjur akan menghubungi peserta melalui WhatsApp untuk pengesahan butiran penghantaran berdasarkan akaun mereka untuk meneruskan pemenuhan hadiah. Setelah pengesahan butiran berjaya, proses pembungkusan dan penghantaran akan mengambil masa kira-kira 4 - 6 minggu.',
					howtotng1: "Buka aplikasi Touch 'n Go eWallet.",
					howtotng2: "Ketik 'Tambah Nilai'.",
					howtotng3: "Ketik 'Baki eWallet'.",
					howtotng4: "Ketik 'PIN Top-Up eWallet' di bawah.",
					howtotng5: "Masukkan PIN Tambah Nilai eWallet TNG dan ketik 'Tambah Nilai'.",
					howtotng6: "Berjaya menebus baucar Touch 'n Go eWallet.",
					howtoshopee1: 'Sah dari masa penebusan sehingga 31 Dis 2024 23:59 malam',
					howtoshopee2: 'Boleh digunakan oleh pengguna Shopee baru dan sedia ada',
					howtoshopee3: 'Sah untuk satu kali penggunaan sahaja, dengan pembelian minimum RM5',
					howtoshopee4: 'Baucar ini hanya boleh digunakan untuk produk Mi Sedaap yang dijual oleh Gentle Supreme Official Store di Shopee ',
					howtoshopee5:
						'Baucar tidak boleh dikembalikan, tidak boleh dipindahkan, tidak boleh ditukarkan dengan wang tunai, dan tidak boleh dijual semula.',
					howtolazada1: 'Sah dari masa penebusan sehingga 31 Dis 2024 23:59 malam',
					howtolazada2: 'Boleh digunakan oleh pengguna Shopee baru dan sedia ada',
					howtolazada3: 'Sah untuk satu kali penggunaan sahaja, dengan pembelian minimum RM10',
					howtolazada4: 'Baucar ini hanya boleh digunakan untuk produk Mi Sedaap yang dijual oleh Gentle Supreme Official Store di Lazada',
					howtolazada5:
						'Baucar tidak boleh dikembalikan, tidak boleh dipindahkan, tidak boleh ditukarkan dengan wang tunai, dan tidak boleh dijual semula.',
					policy1: '1. Introduction ',
					policy2:
						'We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you join our Sedaap Rewards program and tell you about your privacy rights and how the law protects you. ',
					policy3: '2. How We Collect Personal Data ',
					policy4:
						'We collect information about you directly from you, automatically through your use of our Site, and from third parties as following: ',
					policy5:
						'Sedaap Rewards websites. Consumer-directed websites operated by or for Gentle Supreme Sdn Bhd, including sites that We operate under our own domains/URLs and mini-sites that We run on third party social networks such as Facebook or Instagram (“Websites”). ',
					policy6: 'E-mail, text and other electronic messages. Interactions with electronic communications between you and Gentle Supreme Sdn Bhd. ',
					policy7: 'Gentle Supreme Sdn Bhd CES. Communications with our Consumer Engagement Centre (“CES”). ',
					policy8:
						'Offline registration forms. Printed or digital registration and similar forms that We collect via, for example, postal mail, in-store demos, points of sale, campaigns, contests and events. ',
					policy9:
						'Advertising interactions. Interactions with our advertisements (e.g., if you interact with one of our ads on a third-party website, we may receive information about that interaction). ',
					policy10:
						'Data We create. During our interactions with you, we may create Personal Data about you (e.g. records of your purchases from our websites). ',
					policy11:
						'Data from other sources. Third party social networks (e.g. such as Facebook, Google), market research (if feedback not provided on an anonymous basis), third party data aggregators, Gentle Supreme Sdn Bhd promotional partners, public sources and data received when We acquire other companies. ',
					policy12: 'Gentle Supreme Sdn Bhd On Ground Events. Any on-ground events, campaigns or promotions organized by Us ',
					policy13: '3. Personal Data We Collect & Use ',
					policy14: 'We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows: ',
					policy15:
						'Identity Data includes any information you provide to Us that would allow Us to identify you, such as first name, last name, username or similar identifier. ',
					policy16:
						'Contact Data includes any information you provide to Us that would allow Us to contact you, such as billing address, delivery address, email address and telephone numbers. ',
					policy17:
						'Transaction Data includes transactional details or other details of products and services you have purchased from us. Examples include purchased brand, location, date and time. ',
					policy18:
						'Technical Data includes information about the computer system or other technological device that you use to access our Websites, such as the Internet protocol (IP) address used to connect your computer or device to the Internet, operating system type, and web browser type and version. If you access the website via a mobile device such as a smartphone, the collected information will also include, where permitted, your phone’s unique device ID, advertising ID, geo-location, and other similar mobile device data. ',
					policy19:
						'Websites/Communication Usage Data includes information about your actions navigate through and interact with our Websites or newsletters. This includes information such as which links you click on, which pages or content you view and for how long, and other similar information and statistics about your interactions, such as content response times, download errors and length of visits to certain pages. This information is captured using automated technologies such as cookies and web beacons, and is also collected through the use of third party tracking for analytics and advertising purposes.  ',
					policy20:
						'Account login Data includes information that is required to give you access to your specific account profile. Examples include your login ID/email address, screen name, password in unrecoverable form, and/or security question and answer. ',
					policy21:
						'Profile Data includes your username and password, date of birth, age, gender, number of people in your family, preferences, feedback and survey responses, favourite products, hobbies and interests, and household or lifestyle information. ',
					policy22:
						'Consumer-generated Content includes content that you create and then uploading it to our Website. Examples include photos, videos, personal stories, or other similar media or content. Where permitted, We collect and publish consumer-generated content in connection with a variety of activities, including contests and other promotions, website community features, consumer engagement, and third party social networking. ',
					policy23: '4. How We Use Personal Data ',
					policy24:
						'We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: ',
					policy25:
						'Consumer service. We use your Personal Data for consumer service purposes, including responding to your enquiries. This typically requires the use of certain personal contact information and information regarding the reason for your inquiry (e.g. order status, technical issue, product question/complaint, general question, etc.). ',
					policy26:
						'Contests, marketing and other promotions. With your consent (where required), We use your Personal Data to provide you with information about goods or services (e.g. marketing communications, campaigns, promotions or contests). This can be done via any means such as email, ads, SMS, phone calls, instant messaging applications such as WhatsApp etc and postal mailings to the extent permitted by applicable laws. We may also disclose your Personal Data including name, NRIC no. or personal contact information for the purposes of identifying you and Photographs or Videos in public when you participate in a campaign, event or contest in On Ground Events. ',
					policy27:
						'Third party social networks. We may provide functionality on the Site that will allow you to forward and share certain content with a friend or colleague. If you choose to use Gentle Supreme Sdn Bhd sharing functionality to refer someone to our content, products or services, we will use your data and that of the individual you are sharing with to send the content or link you request. In addition, we may offer access to third party sharing functionality, such as third party social media widgets/tools/buttons. If you use that functionality, your use is subject to the third party’s privacy policy and terms. As with all links to non- Gentle Supreme Sdn Bhd websites/content/services, we recommend that you read the privacy policies and terms associated with third party properties carefully. ',
					policy28:
						'Personalisation (offline and online). With your consent (where required), We use your Personal Data (i) to analyse your preferences and habits, (ii) to anticipate your needs based on our analysis of your profile, (iii) to improve and personalise your experience on our Websites and apps; (iv) to ensure that content from our Websites/apps is optimised for you and for your computer or device; (v) to provide you with targeted advertising and content, and (vi) to allow you to participate in interactive features, when you choose to do so. For example, We remember your login ID/email address or screen name so that you can quickly login the next time you visit our site or so that you can easily retrieve the items you previously placed in your shopping cart. Based on this type of information, and with your consent (where required), We also show you specific Gentle Supreme Sdn Bhd content or promotions that are tailored to your interests. The use of your Personal Data is voluntary, which means that you can oppose the processing of your Personal Data for this purpose. For detailed information on how to opt-out please refer to Section 10 below. ',
					policy29:
						'Order fulfilment. We use your Personal Data to process and ship your orders, inform you about the status of your orders, correct addresses and conduct identity verification and other fraud detection activities. This involves the use of certain Personal Data and payment information. This can be done via any means such as email, ads, SMS, phone calls, instant messaging applications such as WhatsApp etc and postal mailings to the extent permitted by applicable laws. ',
					policy30:
						'Other general purposes (e.g. internal or market research, analytic, security). In accordance with applicable laws, We use your Personal Data for other general business purposes, such as maintaining your account, conducting internal or market research and measuring the effectiveness of advertising campaigns. We also use your Personal Data for management and operation of our communications, IT and security systems. ',
					policy31:
						'Legal reasons or merger/acquisition. If Gentle Supreme Sdn Bhd or its assets are acquired by, or merged with, another company including through bankruptcy, We will share your Personal Data with any of our legal successors. We will also disclose your Personal Data to third parties (i) when required by applicable law; (ii) in response to legal proceedings; (iii) in response to a request from a competent law enforcement agency; (iv) to protect our rights, privacy, safety or property, or the public; or (v) to enforce the terms of any agreement or the terms of our Website. ',
					policy32: '5. How We Disclose Your Personal Data ',
					policy33: 'Gentle Supreme Sdn Bhd may disclose your personal data for our business purposes as follows: ',
					policy34:
						'Service providers. These are external companies that We use to help Us run our business (e.g. order fulfilment, payment processing, fraud detection and identity verification, website operation, market research companies, support services, promotions, website development, data analysis, CRC, etc.). Service providers, and their selected staff, are only allowed to access and use your Personal Data on Our behalf for the specific tasks that they have been requested to carry out, based on our instructions, and are required to keep your Personal Data confidential and secure. ',
					policy35:
						'To Comply with Law/Protect. We reserve the right to disclose your personal data as required by law, when we believe disclosure is necessary or appropriate to comply with a regulatory requirement, judicial proceeding, court order, government request, or legal process served on us, or to protect the safety, rights, or property of our customers, the public, Gentle Supreme Sdn Bhd or others. ',
					policy36:
						'Third party recipients using Personal Data for legal reasons or due to merger/acquisition. We will disclose your Personal Data to third parties for legal reasons or in the context of an acquisition or a merger. ',
					policy37: '6. Retention Period',
					policy38:
						'In accordance with applicable laws, We will use your Personal Data for as long as necessary to satisfy the purposes for which your Personal Data was collected or to comply with applicable legal requirements. Personal data used to provide you with a personalized experience will be kept for a duration permitted by applicable laws. ',
					policy39: '7. Disclosure, Storage and/or Transfer of Your Personal Data ',
					policy40:
						'People who can access your Personal Data. Your Personal Data will be processed by our authorized staff or agents, on a need-to-know basis, depending on the specific purposes for which your Personal Data has been collected. ',
					policy41:
						'Measures taken in operating environments. We store your Personal Data in operating environments that use reasonable security measures to prevent unauthorized access. We follow reasonable standards to protect Personal Data. The transmission of information via the Internet is, unfortunately, not completely secure and although We will do our best to protect your Personal Data, We cannot guarantee the security of the data during transmission through our websites/apps. ',
					policy42:
						'Measures We expect you to take. It is important that you also play a role in keeping your Personal Data safe and secure. When signing up for an online account, please be sure to choose an account password that would be difficult for others to guess and never reveal your password to anyone else. You are responsible for keeping this password confidential and for any use of your account. If you use a shared or public computer, never choose to have your login ID/email address or password remembered and make sure to log out of your account every time you leave the computer. You should also make use of any privacy settings or controls We provide you in our website/app. ',
					policy43:
						'Transfer of your Personal Data. Because of the international nature of our business, We may need to transfer your personal data within the Gentle Supreme Sdn Bhd group, and to third parties, in connection with the purposes set out in this Privacy Notice. For this reason, We may transfer your personal data to other countries that may have different laws and data protection compliance requirements to those that apply in Malaysia. ',
					policy44: '8. Changes to This Notice ',
					policy45:
						'This policy may be updated from time to time. We encourage you to review it regularly to stay informed about how we are protecting your information. ',
					policy46: '9. Contact Us ',
					policy47:
						'You have rights under certain privacy laws in relation to your personal data including the right to receive a copy of the personal data we hold about you. To ask questions or make comments on this Notice and our privacy practices or to make a complaint about our compliance with applicable privacy laws, please contact us at: +6019 950 7057 [Hotline for Sedaap Reward] ',
					welcometo: 'Selamat datang',
					landingtext: 'Imbas kod unik yang dicetak pada pembungkusan dalaman pek bundle Mi Sedaap dan dapatkan ganjaran!',
					landingtext2: 'Mi Sedaap dan dapatkan ganjaran!',
					landingtextAllFlavor: 'SEMUA PERISA ',
					getstarted: 'Mulakan',
					policyprivacy: 'Dasar Privasi',
					SelectState: 'Negeri',
					haventSignup: 'Belum Mendaftar?',
					signupNow: 'Daftar Sekarang',
					name: 'Nama',
					mobileNumber: 'Nombor Telefon',
					successAddress: 'Alamat telah berjaya disimpan.',
					howtoRedeemTNG: 'Bagaimana untuk menebus kredit Touch ‘n Go eWallet',
					termandcondition1: 'Hanya SATU (1) Ganjaran PIN Pemulihan boleh digunakan setiap kali pemulihan.',
					termandcondition2:
						"Untuk menggunakan PIN Pemulihan log masuk ke  Sedaap Rewards. Di 'Ganjaran Saya' layari 'TNG', kemudian ketik pada ikon salin bersebelahan dengan kod pemulihan ganjaran yang berkenaan dan ikuti langkah yang disediakan.",
					termandcondition3:
						'Ganjaran Pin Pemulihan yang telah digunakan tidak boleh dikembalikan. Setelah digunakan, jumlahnya tidak boleh ditukar atau dikembalikan untuk kredit, wang tunai, atau gantian seumpama secara separuh atau sepenuhnya.',
					termandcondition4:
						'TNG Digital tidak akan menghubungi pengguna dalam sebarang keadaan untuk mendapatkan maklumat peribadi atau kelayakan akaun untuk kempen ini.',
					termandcondition5: 'Pin pemulihan akan luput pada 31 December 2024. ',
					someTnc1: 'Pin Tambah Nilai Touch ‘n Go RM 1  ',
					someTnc2: 'Pin Tambah Nilai Touch ‘n Go RM 2  ',
					someTnc3: 'Pin Tambah Nilai Touch ‘n Go RM 3  ',
					someTnc4: 'Pin Tambah Nilai Touch ‘n Go RM 5 ',
					someTnc5: 'Pin Tambah Nilai Touch ‘n Go RM 10 ',
					someTnc6: 'Mata Ganjaran Sedaap untuk Penebusan Premium',
					rewardTngDesc: "Touch 'n go eWallet Reload Pin bernilai ",
					orderId: 'ID Pesanan:',
					all: 'Semua',
					peninsularOnly: 'Program ini hanya terdapat di Semenanjung Malaysia',
					selectAddress: 'Pilih Alamat',
					errorName: 'Sila masukkan nama penuh!',
					errorNumber: 'Sila masukkan nombor telefon!',
					errorValidNumber: 'Anda telah memasukkan nombor telefon yang tidak sah.',
					errorAddress: 'Sila masukkan alamat!',
					errorState: 'Sila masukkan bandar!',
					errorCity: 'Sila masukkan negeri!',
					errorPostcode: 'Sila masukkan poskod!',
					errorPostcodeDigit: 'Sila masukkan poskod 5 digit',
					pleaseAvoidSpecialCharactersInName: 'Sila elak menggunakan aksara khas dalam tempat input Nama Penuh',
					items: 'Barang',
					referTNC: '*Rujuk kepada T&C untuk senarai penuh produk yang mengambil bahagian',
					invalidPhoneNumber: 'Anda telah memasukkan nombor telefon yang tidak sah',	
					invalidEmail: 'Anda telah memasukkan emel yang tidak sah',
					invalidNRIC: 'Anda telah memasukkan NRIC yang tidak sah',
				},
			},
			lng:
				localStorage.getItem('language') && localStorage.getItem('language') === 'en'
					? 'en'
					: localStorage.getItem('language') === 'bm'
					? 'bm'
					: 'cn',
		},
	});

export default i18n;
