import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
// import useWindowDimensions from "../function/useWindowDimensions";
import helpline from "../assets/helpline.png";

// const WhatsAppHotline = () => {
//   const dragStartPositionXYRef = useRef();
//   // const { height, width } = useWindowDimensions();
//   const [imgPosition, setPosition] = useState({ x: 0, y: 0 });
//   return (
//     <div
//       style={{
//         // overlay container on top
//         top: '1%',
//         zIndex: 1000,
//         position: "fixed",
//         // prevent dragging to the edge
//         width: "calc(100% - 0px)",
//         // maxWidth: "calc(430px - 40px)",
//         height: "calc(100% - 0px)",
//         // maxHeight: "calc(100vh - 40px)",
//         // ensure the behind elements are clickable
//         pointerEvents: "none",
//         // always the overlay container div
//         // left: "60%",
//         // transform: "translate(20%, 0)",
//       }}>
//       <Draggable
//         // ensure default is always within the parent div
//         // defaultPosition={{
//         //   x: width > 430 ? 430 - 140 : width - 120,
//         //   y: height - 250,
//         // }}
//         style={{
//           position: "absolute",
//           zIndex: 1000,
//           pointerEvents: "auto",
//         }}
//         bounds="parent"
//         onStart={(_, data) => {
//           // Record the dataing position of the drag, so we can detect later if
//           // the user actually dragged the popup or just clicked on it
//           dragStartPositionXYRef.current = { x: data.x, y: data.y };
//         }}
//         onStop={(_, data) => {
//           // Only treat the drag as a real one if the popup moved at least a
//           // threshold number of pixels in any direction
//           const THRESHOLD = 2;
//           const { x, y } = dragStartPositionXYRef.current ?? { x: 0, y: 0 };
//           const wasDragged =
//             Math.abs(data.x - x) > THRESHOLD &&
//             Math.abs(data.y - y) > THRESHOLD;
//           // if (!wasDragged) {
//           window.open("https://wa.me/+60199507057", "_blank");
//           // }
//         }}
//       >
//         <div
//           style={{
//             top: '40%',
//             right: '5%',
//             position: "fixed",
//             pointerEvents: "auto", // Ensure pointer events are enabled for Draggable
//           }}
//         >
//           <a href="https://wa.me/+601995070572" target="_blank">
//             <img
//               src={helpline}
//               alt="whatsapp"
//               style={{ objectFit: "contain", width: '90px' }}
//             />
//           </a>
//         </div>
//       </Draggable>
//     </div>
//   );
// };

const WhatsAppHotline = ({ language }) => {
	const dragStartPositionXYRef = useRef();
	const [imgPosition, setPosition] = useState({ x: 0, y: 0 });

	return (
		<div
			style={{
				top: 20,
				zIndex: 1000,
				position: "fixed",
				width: "calc(100% - 40px)",
				maxWidth: "calc(430px - 40px)",
				height: "calc(100% - 40px)",
				maxHeight: "calc(100vh - 40px)",
				pointerEvents: "none",
				left: "50%",
				transform: "translate(-50%, 0)"
			}}
		>
			<Draggable
				style={{
					position: "absolute",
					zIndex: 1000,
					pointerEvents: "auto"
				}}
				bounds="parent"
				onStart={(_, data) => {
					dragStartPositionXYRef.current = { x: data.x, y: data.y };
				}}
				onStop={(_, data) => {
					const THRESHOLD = 2;
					const { x, y } = dragStartPositionXYRef.current ?? { x: 0, y: 0 };
					const wasDragged = Math.abs(data.x - x) > THRESHOLD && Math.abs(data.y - y) > THRESHOLD;
					if (!wasDragged) {
						window.open("https://wa.me/+60199507057", "_blank");
					}
				}}
			>
				<div
					style={{
						top: "40%",
						right: "5%",
						position: "fixed",
						pointerEvents: "auto"
					}}
				>
					<img src={helpline} alt="whatsapp" style={{ objectFit: "contain", width: "75px" }} />
				</div>
			</Draggable>
		</div>
	);
};

export default WhatsAppHotline;
